<script setup>
import {MEDIA_PACK_LAYOUT_REQUESTED, MEDIA_PACK_SHIFT_REQUESTED} from "~/types/bus-event-names"

const { $packery, $imagesLoaded } = useNuxtApp()

const packOptions = {
  itemSelector: '.u-pack-me',
  transitionDuration: 0,
  percentPosition: true,
}

const packElement = ref(null)
let packery

function goPack() {
  if (packery) {
    packery.reloadItems()
    packery.layout()
  } else {
    packery = new $packery(packElement.value, packOptions)
  }

  const loaded = new $imagesLoaded(packElement.value)
  loaded.on('progress', () => {
    packery.layout()
  })
}

useBusEvent(MEDIA_PACK_SHIFT_REQUESTED, onShiftRequested)
useBusEvent(MEDIA_PACK_LAYOUT_REQUESTED, onLayoutRequested)

onMounted(() => {
  if (packElement.value) {
    goPack()
  }
})

async function onShiftRequested() {
  await nextTick()
  packery?.shiftLayout()
}

async function onLayoutRequested() {
  await nextTick()
  packery?.layout()
}

onUpdated(() => {
  goPack()
})

defineExpose({
  refreshLayout: goPack
})

</script>

<template>
  <div class="-ml-half-bento -mr-half-bento -mt-half-bento pb-16">
    <div ref="packElement" class="flex flex-wrap">
      <slot />
    </div>
  </div>
</template>